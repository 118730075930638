/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography, Card, CardTitle, CardContent } from '@one-thd/sui-atomic-components';
import classNames from 'classnames/bind';

import { RecycleProgramSkeleton } from '../subcomponents/Skeletons';

import { emtContentDataModel } from '../dataModels';
import { PAGE_NAMES } from '../../constants';
import styles from './details.module.scss';

const cx = classNames.bind(styles);

const StoreDetailsRecycleProgram = ({ storeId }) => {
  const { data, loading, error } = useDataModel('emtContent', {
    variables: {
      pageName: PAGE_NAMES.DETAILS,
      contentId: storeId,
    }
  });

  if (loading) return <RecycleProgramSkeleton />;

  const storeDetailsResponse = data?.emtContent?.content?.storeDetails;
  const isArray = Array.isArray(storeDetailsResponse);
  const storeDetailsContent = isArray ? storeDetailsResponse?.[0] : storeDetailsResponse?.recyclingPrograms;

  const header = storeDetailsContent?.header || '';
  const subHeader = storeDetailsContent?.subHeader || '';
  const content = storeDetailsContent?.content || [];

  if (!header || !subHeader || !content || !content.length || error) return null;

  const titleTypography = <Typography variant="h2">{header}</Typography>;
  const subTitleTypography = <Typography variant="body-base" component="h3">{subHeader}</Typography>;

  return (
    <Card data-component="StoreDetailsRecycleProgram" PaperProps={{ className: 'sui-size-full' }}>
      <CardTitle header={titleTypography} subheader={subTitleTypography} />
      <CardContent disablePadding>
        {content.map(({ imageUrl, text }, index) => (
          <div key={index} className="sui-flex sui-items-center sui-gap-5">
            <img
              loading="lazy"
              width={44}
              height={44}
              src={imageUrl}
              alt="eco_image"
            />
            <div className={cx('underline_text')}>
              <Typography dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          </div>
        ))}
      </CardContent>
    </Card>
  );
};

StoreDetailsRecycleProgram.propTypes = {
  storeId: PropTypes.string.isRequired
};

StoreDetailsRecycleProgram.displayName = 'StoreDetailsRecycleProgram';
StoreDetailsRecycleProgram.dataModel = emtContentDataModel;

export default StoreDetailsRecycleProgram;