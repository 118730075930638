import React from 'react';
import {
  Skeleton,
  SkeletonLine,
  SkeletonContent
} from '@one-thd/sui-atomic-components';

const FAQSkeleton = () => {
  return (
    <Skeleton density="normal" className="sui-w-full">
      <SkeletonContent>
        <SkeletonLine variant="heading" />
      </SkeletonContent>
      { Array(3).fill('faq').map((__, index) => (
        <SkeletonContent key={index}>
          <SkeletonLine variant="heading" />
          <SkeletonLine fullWidth />
        </SkeletonContent>
      ))}
    </Skeleton>
  );
};

export default FAQSkeleton;