import React from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography, Card, CardContent, CardTitle } from '@one-thd/sui-atomic-components';

import { ContentSkeleton } from '../subcomponents/Skeletons';

import { emtContentDataModel } from '../dataModels';
import { PAGE_NAMES } from '../../constants';

const LocalCityPageContent = ({ guid }) => {
  const { data, loading, error } = useDataModel('emtContent', {
    variables: {
      pageName: PAGE_NAMES.LOCAL_CITY_PAGE,
      contentId: guid,
    }
  });

  if (loading) return <ContentSkeleton />;

  const localCityPageContent = data?.emtContent?.content?.localCityPage?.storeDetails;
  const content = localCityPageContent?.content || [];
  const header = localCityPageContent?.header || '';

  if (!content || !header || !content?.length || error) return null;

  const titleTypography = <Typography variant="h2">{header}</Typography>;

  return (
    <Card data-component="LocalCityPageContent" PaperProps={{ className: '[&_a]:sui-underline' }}>
      <CardTitle header={titleTypography} />
      <CardContent disablePadding>
        {content.map(({ text }, index) => <Typography key={index} dangerouslySetInnerHTML={{ __html: text }} />)}
      </CardContent>
    </Card>
  );
};

LocalCityPageContent.propTypes = {
  guid: PropTypes.string.isRequired
};

LocalCityPageContent.displayName = 'LocalCityPageContent';
LocalCityPageContent.dataModel = emtContentDataModel;

export default LocalCityPageContent;