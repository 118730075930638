import React from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';
import classNames from 'classnames/bind';

import { ContentSkeleton } from '../subcomponents/Skeletons';

import { emtContentDataModel } from '../dataModels';
import { PAGE_NAMES } from '../../constants';
import styles from './rentals.module.scss';

const cx = classNames.bind(styles);

const RentalsContent = ({ storeId }) => {
  const { data, loading, error } = useDataModel('emtContent', {
    variables: {
      pageName: PAGE_NAMES.RENTALS,
      contentId: storeId,
    }
  });

  if (loading) return <ContentSkeleton />;

  const rentalsContentResponse = data?.emtContent?.content?.rentals;
  const isArray = Array.isArray(rentalsContentResponse);
  const rentalsContent = isArray ? rentalsContentResponse?.[0] : rentalsContentResponse?.storeDetails;

  const header = rentalsContent?.header || '';
  const content = rentalsContent?.content || [];

  if (!rentalsContentResponse || !content?.length || !header || error) return null;

  return (
    <div className="sui-bg-primary sui-p-4 sui-flex sui-flex-col sui-gap-4">
      <Typography variant="h2" weight="display">{header}</Typography>

      {content.map(({ text }, index) => (
        <div className={cx('underline-text')} key={index}>
          <Typography dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      ))}
    </div>
  );
};

RentalsContent.propTypes = {
  storeId: PropTypes.string.isRequired
};

RentalsContent.displayName = 'RentalsContent';
RentalsContent.dataModel = emtContentDataModel;

export default RentalsContent;