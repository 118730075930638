import React from 'react';
import { Heading } from '@thd-olt-component-react/core-ui';
import { shape, string } from 'prop-types';

export const ProductCategoryContent = ({ content }) => {
  if (!content || !content?.title || !content?.data) return null;

  return (
    <div data-component="ProductCategoryContent" className="emt-copyblock">
      <div className="emt-copyblock-heading">
        <Heading level="2" title={content?.title} underline />
      </div>
      <div dangerouslySetInnerHTML={{ __html: content?.data }} />
    </div>
  );
};

ProductCategoryContent.propTypes = {
  content: shape({
    title: string,
    data: string
  })
};

ProductCategoryContent.defaultProps = {
  content: null
};
