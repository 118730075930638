import React from 'react';
import PropTypes from 'prop-types';

import FAQ from './FAQ';

import './faq.scss';

const FAQList = ({ faqs }) => {
  if (faqs.length <= 0) return null;

  return (
    <>
      {faqs.map((faq, index) => (
        <FAQ
          key={index}
          faq={faq}
          index={index}
        />
      ))}
    </>
  );
};

FAQList.propTypes = {
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string
    })
  ).isRequired,
};

FAQList.defaultProps = { };

export default FAQList;