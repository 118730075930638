import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography, Card } from '@one-thd/sui-atomic-components';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { RelatedCategoriesPill } from './RelatedCategoriesPill';
import './related-categories.scss';

export function RelatedCategories({ categories, noOfItems, nopadding }) {
  const { channel } = useContext(ExperienceContext);

  const validCategories = categories.filter(
    (category) => category?.pageTitle && category?.canonicalUrl
  ).slice(0, noOfItems);

  if (!validCategories.length) return null;

  return (
    <Card
      className="sui-h-full sui-leading-none sui-border-none sui-bg-transparent"
      data-component="RelatedCategories"
      disablePadding={nopadding}
    >
      <Typography variant="h2" weight="display">Related Categories</Typography>
      <div
        className="related-categories sui-flex sui-flex-row sui-flex-wrap sui-leading-tight sui-gap-4"
      >
        {validCategories.map(
          ({ categoryId, pageTitle, canonicalUrl }, index) => {
            return (
              <RelatedCategoriesPill
                key={categoryId}
                categoryObject={{ categoryId, pageTitle, canonicalUrl }}
                index={index}
              />
            );
          }
        )}
      </div>
    </Card>
  );
}

RelatedCategories.defaultProps = {
  categories: [],
  noOfItems: 10,
  nopadding: false
};

const Categories = PropTypes.shape({
  categoryId: PropTypes.string,
  pageTitle: PropTypes.string,
  canonicalUrl: PropTypes.string
});

RelatedCategories.propTypes = {
  categories: PropTypes.arrayOf(Categories),
  noOfItems: PropTypes.number,
  nopadding: PropTypes.bool
};
