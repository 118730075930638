import React from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';

import { FAQList } from '../subcomponents/FAQ';
import { FAQSkeleton } from '../subcomponents/Skeletons';

import { emtContentDataModel } from '../dataModels';
import { PAGE_NAMES } from '../../constants';

const RentalsFAQ = ({ storeId }) => {
  const { data, loading, error } = useDataModel('emtContent', {
    variables: {
      pageName: PAGE_NAMES.RENTALS,
      contentId: storeId,
    }
  });

  if (loading) return <FAQSkeleton />;

  const rentalsContent = data?.emtContent?.content?.rentals;
  const isArray = Array.isArray(rentalsContent);
  const faqs = isArray ? rentalsContent?.[0]?.faq : rentalsContent?.faq;
  const faqTitle = isArray ? rentalsContent?.[0]?.faqHeader : rentalsContent?.faqHeader;

  if (!rentalsContent || !faqs?.length || error) return null;

  return (
    <div className="sui-bg-primary sui-p-4 sui-flex sui-flex-col sui-gap-4">
      {faqTitle && <Typography variant="h2">{faqTitle}</Typography>}
      <div className="sui-flex sui-flex-col sui-gap-4">
        <FAQList faqs={faqs} />
      </div>
    </div>
  );
};

RentalsFAQ.propTypes = {
  storeId: PropTypes.string.isRequired
};

RentalsFAQ.displayName = 'RentalsFAQ';
RentalsFAQ.dataModel = emtContentDataModel;

export default RentalsFAQ;