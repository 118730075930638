/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import classNames from 'classnames/bind';

import { ContentSkeleton } from '../subcomponents/Skeletons';

import { emtContentDataModel } from '../dataModels';
import { PAGE_NAMES } from '../../constants';
import styles from './home-services.module.scss';

const cx = classNames.bind(styles);

const HomeServicesContent = ({ storeId }) => {
  const { data, loading, error } = useDataModel('emtContent', {
    variables: {
      pageName: PAGE_NAMES.HOME_SERVICES,
      contentId: storeId,
    }
  });

  if (loading) return <ContentSkeleton />;

  const homeServicesContentResponse = data?.emtContent?.content?.homeServices;
  const isArray = Array.isArray(homeServicesContentResponse);
  const homeServicesContent = isArray ? homeServicesContentResponse?.[0] : homeServicesContentResponse?.storeDetails;

  const header = homeServicesContent?.header || '';
  const content = homeServicesContent?.content || [];

  if (!homeServicesContentResponse || !content?.length || !header || error) return null;

  return (
    <div className="sui-bg-primary sui-p-4 sui-flex sui-flex-col sui-gap-4">
      <Typography variant="h2" weight="display">{header}</Typography>
      {content.map(({ imageUrl, text, category }, index) => (
        <Row key={index}>
          <Col xs={3}>
            <img
              loading="lazy"
              width="200"
              height="200"
              className={cx('service-content__image')}
              src={imageUrl}
              alt={category || ''}
            />
          </Col>
          <Col xs={9} className={cx('service-content__item')}>
            {category && (
              <div className="sui-mb-2">
                <Typography variant="h3" weight="display">{category}</Typography>
              </div>
            )}
            <div className={cx('service-content__item--text')}>
              <Typography dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          </Col>
        </Row>
      ))}
    </div>
  );
};

HomeServicesContent.propTypes = {
  storeId: PropTypes.string.isRequired
};

HomeServicesContent.displayName = 'HomeServicesContent';
HomeServicesContent.dataModel = emtContentDataModel;

export default HomeServicesContent;