/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';
import classNames from 'classnames/bind';

import { ContentSkeleton } from '../subcomponents/Skeletons';

import { emtContentDataModel } from '../dataModels';
import { PAGE_NAMES } from '../../constants';
import styles from './garden-center.module.scss';

const cx = classNames.bind(styles);

const GardenCenterContent = ({ storeId }) => {
  const { data, loading, error } = useDataModel('emtContent', {
    variables: {
      pageName: PAGE_NAMES.GARDEN_CENTER,
      contentId: storeId,
    }
  });

  if (loading) return <ContentSkeleton />;

  const gardenContentResponse = data?.emtContent?.content?.gardenCenter;
  const isArray = Array.isArray(gardenContentResponse);
  const gardenCenterContent = isArray ? gardenContentResponse?.[0] : gardenContentResponse?.storeDetails;

  const header = gardenCenterContent?.header;
  const content = gardenCenterContent?.content?.[0];

  if (!gardenContentResponse || !header || !content || error) return null;

  return (
    <div data-component="GardenCenterContent" className="sui-bg-primary sui-p-4 sui-flex sui-flex-col sui-gap-4">
      <Typography variant="h2" weight="display">{header}</Typography>
      <div className={cx('underline-text')}>
        <Typography dangerouslySetInnerHTML={{ __html: content?.text }} />
      </div>
    </div>
  );
};

GardenCenterContent.propTypes = {
  storeId: PropTypes.string.isRequired
};

GardenCenterContent.displayName = 'GardenCenterContent';
GardenCenterContent.dataModel = emtContentDataModel;

export default GardenCenterContent;
