import React from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography, Card, CardContent, CardTitle } from '@one-thd/sui-atomic-components';

import { ContentSkeleton } from '../subcomponents/Skeletons';

import { emtContentDataModel } from '../dataModels';
import { PAGE_NAMES } from '../../constants';

const ProDeskContent = ({ storeId }) => {
  const { data, loading, error } = useDataModel('emtContent', {
    variables: {
      pageName: PAGE_NAMES.PRO_DESK,
      contentId: storeId,
    }
  });

  if (loading) return <ContentSkeleton />;

  const proDeskContent = data?.emtContent?.content?.proDesk?.storeDetails;
  const content = proDeskContent?.content || [];
  const header = proDeskContent?.header || '';

  if (!content || !header || !content?.length || error) return null;

  const titleTypography = <Typography variant="h2">{header}</Typography>;

  return (
    <Card data-component="ProDeskContent" PaperProps={{ className: '[&_a]:sui-underline' }}>
      <CardTitle header={titleTypography} />
      <CardContent disablePadding>
        {content.map(({ text }, index) => <Typography key={index} dangerouslySetInnerHTML={{ __html: text }} />)}
      </CardContent>
    </Card>
  );
};

ProDeskContent.propTypes = {
  storeId: PropTypes.string.isRequired
};

ProDeskContent.displayName = 'ProDeskContent';
ProDeskContent.dataModel = emtContentDataModel;

export default ProDeskContent;