import React from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';
import classNames from 'classnames/bind';

import { ContentSkeleton } from '../subcomponents/Skeletons';

import { emtContentDataModel } from '../dataModels';
import { PAGE_NAMES } from '../../constants';
import styles from './details.module.scss';

const cx = classNames.bind(styles);

const StoreDetailsContent = ({ storeId }) => {
  const { data, loading, error } = useDataModel('emtContent', {
    variables: {
      pageName: PAGE_NAMES.DETAILS,
      contentId: storeId,
    }
  });

  if (loading) return <ContentSkeleton />;

  const storeDetailsResponse = data?.emtContent?.content?.storeDetails;
  const isArray = Array.isArray(storeDetailsResponse);
  const storeDetailsContent = isArray ? storeDetailsResponse?.[1] : storeDetailsResponse?.storeDetails;

  const content = storeDetailsContent?.content || [];
  const header = storeDetailsContent?.header || '';

  if (!content || !header || !content?.length || error) return null;

  return (
    <div className="sui-bg-primary sui-p-4 sui-flex sui-flex-col sui-gap-4">
      <Typography variant="h2" weight="display">{header}</Typography>
      {content.map(({ text }, index) => (
        <div className={cx('underline_text')} key={index}>
          <Typography dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      )
      )}
    </div>
  );
};

StoreDetailsContent.propTypes = {
  storeId: PropTypes.string.isRequired
};

StoreDetailsContent.displayName = 'StoreDetailsContent';
StoreDetailsContent.dataModel = emtContentDataModel;

export default StoreDetailsContent;