import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Typography
} from '@one-thd/sui-atomic-components';

const FAQ = ({ faq, index }) => {
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    setExpanded(index === 0);
  }, []);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded((prevState) => !prevState)}
    >
      <AccordionHeader>
        {faq.question}
      </AccordionHeader>
      <AccordionBody>
        <div className="faq">
          <Typography dangerouslySetInnerHTML={{ __html: faq.answer }} />
        </div>
      </AccordionBody>
    </Accordion>
  );
};

FAQ.propTypes = {
  faq: PropTypes.shape({
    question: PropTypes.string,
    answer: PropTypes.string
  }).isRequired,
  index: PropTypes.number.isRequired
};

FAQ.defaultProps = { };

export default FAQ;