import React from 'react';
import PropTypes from 'prop-types';
import { Pill } from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';
import { track } from './related-categories-analytics';

const RelatedCategoriesPill = ({ categoryObject, index }) => {
  const { categoryId, pageTitle, canonicalUrl } = categoryObject;

  const getCategory = (text) => {
    const words = text.split(' ');
    const wordsUpperCase = words.map(
      (word, iWord) => {
        if (iWord !== 0) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }
        return word;
      }
    );
    return wordsUpperCase.join('');
  };

  const { ref, clickID } = useImpression({
    data: {
      id: categoryId,
      name: 'RelatedCategories',
      component: 'RelatedCategories',
      position: index + 1,
      type: 'content',
      category: getCategory(pageTitle)
    }
  });
  return (
    <div
      className="sui-bg-primary sui-rounded-full"
      key={categoryId}
      ref={ref}
      /* eslint-disable-next-line react/no-unknown-property */
      clickid={clickID}
    >
      <Pill
        label={pageTitle}
        component="a"
        href={canonicalUrl}
        onClick={() => track(pageTitle)}
        clickable
      />
    </div>
  );
};

export { RelatedCategoriesPill };

RelatedCategoriesPill.propTypes = {
  categoryObject: PropTypes.shape({
    categoryId: PropTypes.string,
    pageTitle: PropTypes.string,
    canonicalUrl: PropTypes.string
  }).isRequired,
  index: PropTypes.number.isRequired
};
