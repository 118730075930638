import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Card, CardTitle, CardContent } from '@one-thd/sui-atomic-components';
import { Carousel } from '@one-thd/sui-carousel';
import { useDataModel } from '@thd-nucleus/data-sources';
import { emtContentDataModel } from '../dataModels';

const IdeasCarousel = ({ contentId, pageName, sectionTitle }) => {
  const { loading, data, error } = useDataModel(
    'emtContent',
    {
      variables: {
        contentId,
        pageName
      }
    }
  );

  const regionalLinks = data?.emtContent?.content?.gardenCenterArticles?.regional_links;

  if (!data || loading || error || !regionalLinks || regionalLinks?.length === 0) {
    return null;
  }

  const titleTypography = <Typography variant="h2" weight="display">{sectionTitle}</Typography>;

  return (
    <Card data-component="IdeasCarousel">
      <CardTitle header={titleTypography} />
      <CardContent disablePadding>
        <Carousel
          spaceBetween={16}
          disableShadow
          disableMargin
        >
          {regionalLinks.map(({ url, title, img: imageUrl }) => (
            <article key={title}>
              <a href={url}>
                <div className="sui-flex sui-flex-col sui-items-center sui-gap-2">
                  <img
                    alt={title}
                    className="sui-aspect-square sui-object-cover"
                    src={imageUrl?.replace('<SIZE>', '400')}
                    height="183"
                    width="183"
                  />

                  <Typography
                    variant="h5"
                    component="h3"
                    lineClamp="3"
                    align="center"
                  >
                    {title}
                  </Typography>
                </div>
              </a>
            </article>
          ))}
        </Carousel>
      </CardContent>
    </Card>
  );
};

IdeasCarousel.propTypes = {
  contentId: PropTypes.string.isRequired,
  pageName: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string
};

IdeasCarousel.defaultProps = {
  sectionTitle: ''
};

IdeasCarousel.dataModel = emtContentDataModel;

IdeasCarousel.displayName = 'IdeasCarousel';

export default IdeasCarousel;