import React from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';

import { FAQList } from '../subcomponents/FAQ';
import { FAQSkeleton } from '../subcomponents/Skeletons';

import { emtContentDataModel } from '../dataModels';
import { PAGE_NAMES } from '../../constants';

const GardenCenterFAQ = ({ title, storeId }) => {
  const { data, loading, error } = useDataModel('emtContent', {
    variables: {
      pageName: PAGE_NAMES.GARDEN_CENTER,
      contentId: storeId,
    }
  });

  if (loading) return <FAQSkeleton />;

  const gardenContent = data?.emtContent?.content?.gardenCenter;
  const isArray = Array.isArray(gardenContent);
  const faqs = isArray ? gardenContent?.[0]?.faq : gardenContent?.faq;
  const faqTitle = isArray ? gardenContent?.[0]?.faqHeader : gardenContent?.faqHeader;

  if (!data || !gardenContent || !title || !faqs?.length || error) return null;

  return (
    <div className="sui-bg-primary sui-p-4 sui-flex sui-flex-col sui-gap-4">
      <Typography variant="h2" weight="display">{faqTitle || title}</Typography>
      <div className="sui-flex sui-flex-col sui-gap-4">
        <FAQList faqs={faqs} />
      </div>
    </div>
  );
};

GardenCenterFAQ.propTypes = {
  title: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
};

GardenCenterFAQ.displayName = 'GardenCenterFAQ';
GardenCenterFAQ.dataModel = emtContentDataModel;

export default GardenCenterFAQ;