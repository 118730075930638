import React from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';

import { FAQList } from '../subcomponents/FAQ';
import { FAQSkeleton } from '../subcomponents/Skeletons';

import { emtContentDataModel } from '../dataModels';
import { PAGE_NAMES } from '../../constants';

const HomeServicesFAQ = ({ storeId }) => {
  const { data, loading, error } = useDataModel('emtContent', {
    variables: {
      pageName: PAGE_NAMES.HOME_SERVICES,
      contentId: storeId,
    }
  });

  if (loading) return <FAQSkeleton />;

  const homeServicesContentResponse = data?.emtContent?.content?.homeServices;
  const isArray = Array.isArray(homeServicesContentResponse);
  const faqs = isArray ? homeServicesContentResponse?.[0]?.faq : homeServicesContentResponse?.faq;
  const faqTitle = isArray ? homeServicesContentResponse?.[0]?.faqHeader : homeServicesContentResponse?.faqHeader;

  if (!homeServicesContentResponse || !faqs?.length || error) return null;

  return (
    <div className="sui-bg-primary sui-p-4 sui-flex sui-flex-col sui-gap-4">
      {faqTitle && <Typography variant="h2">{faqTitle}</Typography>}
      <div className="sui-flex sui-flex-col sui-gap-4">
        <FAQList faqs={faqs} />
      </div>
    </div>
  );
};

HomeServicesFAQ.propTypes = {
  storeId: PropTypes.string.isRequired
};

HomeServicesFAQ.displayName = 'HomeServicesFAQ';
HomeServicesFAQ.dataModel = emtContentDataModel;

export default HomeServicesFAQ;