import React from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';

import { FAQList } from '../subcomponents/FAQ';
import { FAQSkeleton } from '../subcomponents/Skeletons';

import { emtContentDataModel } from '../dataModels';
import { PAGE_NAMES } from '../../constants';

const LocalCityPageFAQ = ({ guid }) => {
  const { data, loading, error } = useDataModel('emtContent', {
    variables: {
      pageName: PAGE_NAMES.LOCAL_CITY_PAGE,
      contentId: guid,
    }
  });

  if (loading) return <FAQSkeleton />;

  const localCityPageFaq = data?.emtContent?.content?.localCityPage;
  const faqs = localCityPageFaq?.faq;
  const faqHeader = localCityPageFaq?.faqHeader;

  if (!data || !localCityPageFaq || !faqs?.length || error) return null;

  return (
    <div className="sui-bg-primary sui-p-4 sui-flex sui-flex-col sui-gap-4">
      <Typography variant="h2" weight="display">{faqHeader}</Typography>
      <div className="sui-flex sui-flex-col sui-gap-4">
        <FAQList faqs={faqs} />
      </div>
    </div>
  );
};

LocalCityPageFAQ.propTypes = {
  guid: PropTypes.string.isRequired
};

LocalCityPageFAQ.displayName = 'LocalCityPageFAQ';
LocalCityPageFAQ.dataModel = emtContentDataModel;

export default LocalCityPageFAQ;