import React from 'react';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';

import { EmtQuestionsAndAnswersComponent } from './QuestionsAndAnswers/EmtQuestionsAndAnswers';
import { ContentSkeleton } from './subcomponents/Skeletons';

const HydratedEmtQuestionsAndAnswers = withHydrator({
  id: 'emt-questions-and-answers',
  scrollBuffer: 750,
  delay: 2000,
  preserveCtxVal: 'clientStore',
  placeholder: (<ContentSkeleton />)
}, EmtQuestionsAndAnswersComponent);

const DynamicEmtQuestionsAnswers = withDynamicComponent(HydratedEmtQuestionsAndAnswers);
export const EmtQuestionsAndAnswers = withErrorBoundary(DynamicEmtQuestionsAnswers);

EmtQuestionsAndAnswers.dataModel = EmtQuestionsAndAnswersComponent.dataModel;
EmtQuestionsAndAnswers.propTypes = EmtQuestionsAndAnswersComponent.propTypes;