/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
  params,
  useDataModel,
  arrayOf,
  string,
  shape
} from '@thd-nucleus/data-sources';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { ProductCategoryContent } from './ProductCategoryContent';
import { RelatedCategories } from './subcomponents/related-categories/RelatedCategories';

export const EmtGeneratedContent = (props) => {
  const { nvalue, type, nopadding } = props;
  const nval = (nvalue?.indexOf('N-') < 0) ? `N-${nvalue}` : nvalue;

  const { loading, data, error } = useDataModel('emtGeneratedContent', { variables: { nvalue: nval }, skip: !nval });

  if ((error && !data) || loading || !data?.emtGeneratedContent) { return null; }

  const relCatImpression = {
    id: '',
    component: 'RelatedCategories',
    name: 'RelatedCategories',
    type: 'content'
  };

  switch (type) {
  case 'productCategoryContent':
    return <ProductCategoryContent content={data?.emtGeneratedContent?.productCategoryContent} />;

  case 'relatedCategories':
    return (
      <ImpressionProvider
        data={relCatImpression}
      >
        <RelatedCategories
          categories={data?.emtGeneratedContent?.relatedCategoryContent?.relatedCategories}
          nopadding={nopadding}
        />
      </ImpressionProvider>
    );
  default:
    return null;
  }

};
EmtGeneratedContent.displayName = 'EmtGeneratedContent';

EmtGeneratedContent.defaultProps = {
  type: '',
  nvalue: '',
  nopadding: false
};

EmtGeneratedContent.propTypes = {
  nvalue: PropTypes.string,
  type: PropTypes.string,
  nopadding: PropTypes.bool
};

const paramsForQuery = params({ nvalue: string().isRequired() });
EmtGeneratedContent.dataModel = {
  emtGeneratedContent: paramsForQuery.shape({
    productCategoryContent: shape({
      title: string(),
      data: string()
    }),
    relatedCategoryContent: shape({
      relatedCategories: arrayOf(
        shape({
          categoryId: string(),
          pageTitle: string(),
          canonicalUrl: string()
        })
      )
    })
  })
};
