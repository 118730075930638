import React from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';

import { FAQList } from '../subcomponents/FAQ';
import { FAQSkeleton } from '../subcomponents/Skeletons';

import { emtContentDataModel } from '../dataModels';
import { PAGE_NAMES } from '../../constants';

const ProDeskFAQ = ({ title, storeId }) => {
  const { data, loading, error } = useDataModel('emtContent', {
    variables: {
      pageName: PAGE_NAMES.PRO_DESK,
      contentId: storeId,
    }
  });

  if (loading) return <FAQSkeleton />;

  const proDeskFaq = data?.emtContent?.content?.proDesk;
  const faqs = proDeskFaq?.faq;
  const faqTitle = proDeskFaq?.faqHeader;

  if (!data || !proDeskFaq || !faqs?.length || error) return null;

  return (
    <div className="sui-bg-primary sui-p-4 sui-flex sui-flex-col sui-gap-4">
      <Typography variant="h2" weight="display">{faqTitle || title}</Typography>
      <div className="sui-flex sui-flex-col sui-gap-4">
        <FAQList faqs={faqs} />
      </div>
    </div>
  );
};

ProDeskFAQ.propTypes = {
  title: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
};

ProDeskFAQ.displayName = 'ProDeskFAQ';
ProDeskFAQ.dataModel = emtContentDataModel;

export default ProDeskFAQ;